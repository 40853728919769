body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  width: 98%;
  height: 25px;
  font-family: 'Open Sans', sans-serif;
  font-size: large;
}

select {
  width: 98%;
  height: 25px;
  font-family: 'Open Sans', sans-serif;
  font-size: large;
}

button {
  font-family: 'Open Sans', sans-serif;
  font-size: large;
}

label {
  font-family: 'Open Sans', sans-serif;
  font-size: large;
}

.error{
  color: red;
}

.checkbox {
  width: 40px;
   vertical-align: bottom;
}

.container {
  display: flex;
  /* flex-wrap: nowrap; */
  /* background-color: DodgerBlue; */
  flex-direction: column;
}

.container>div {
  background-color: #f1f1f1;
  padding: 4px;
  margin: 10px 10px 0 10px;
  text-align: left;
}

.button {
  height: 50px;
  width: 100%;
  font-weight: 600;
}

 /* Nav bar */
 ul.header li {
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: rgb(152, 170, 248);
  padding: 0;
}
ul.header li a {
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

 /* The Spinner */

 #loaderBody {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

#loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  margin-left: -75px;
  margin-top: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* The Modal */

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  border-radius: 15px;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
}

.modal-section>div>div,
.modal-section>form>div {
  background-color: white;
  padding: 5px;
  font-weight: 600;
}

.modal-checkbox-label {
  padding-left: 5px;
}

.model-display {
  display: block;
}

.model-header {
  background-color: #3498db;
  margin: 0;
  border-radius: 13px 13px 0 0;
  padding: 20px;
  color: white;
}

:root {
  --amplify-primary-color:#262c49;
  --amplify-white: #c2c6dc;
}

.logo-dark {
  background-image: url("../src/assets/img/logo/dark-logo.png");
  background-repeat:no-repeat;
  background-size:contain;
  height: 80px;
  width: 180px;
  margin-top: -20px;
}

.logo-light {
  background-image: url("../src/assets/img/logo/light-logo.png");
  background-repeat:no-repeat;
  background-size:contain;
  height: 80px;
  width: 180px;
  margin-top: -20px;
}

